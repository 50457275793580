import * as http from '../http'

export default {
  //查询获取 operationLog 列表(通过page分页)
  queryOperationLogList: params => {
    return http.post('/sysOperationLog/pageList', params)
  },
  //业务类型下拉框
  operationBusinessCombobox: params => {
    return http.post('/sysOperationLog/findBusiness', params)
  },
  //操作类型下拉框
  operationTypeCombobox: params => {
    return http.post('/sysOperationLog/findOperation', params)
  }
}
