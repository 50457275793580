<template>
  <el-row class="warp">
    <el-col :span="24">
      <!--工具条-->
      <el-form :inline="true" :model="searchForm" size="small">
        <el-form-item>
          <el-select v-model="searchForm.operationBusiness" placeholder="业务类型" @change="handleSearch">
            <el-option v-for="item in businessOptions" :key="item.operationBusiness" :label="item.operationBusinessName" :value="item.operationBusiness"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchForm.operationType" placeholder="操作类型" @change="handleSearch">
            <el-option v-for="item in operationOptions" :key="item.operationType" :label="item.operationTypeName" :value="item.operationType"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.operatorName" placeholder="操作者姓名" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="searchForm.startEndTime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="handleSearch"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询 </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleReset">重置 </el-button>
        </el-form-item>
      </el-form>
      <!--列表-->
      <el-table :data="listData">
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="operationBusinessName" label="业务类型" width="120"></el-table-column>
        <el-table-column prop="operationTypeName" label="操作类型" width="80"></el-table-column>
        <el-table-column prop="operatorIp" label="操作者IP" width="160"></el-table-column>
        <el-table-column prop="operatorName" label="操作者姓名" width="140"></el-table-column>
        <el-table-column prop="workNo" label="操作者员工编号" width="200"></el-table-column>
        <el-table-column prop="operatorTime" label="操作时间"></el-table-column>
      </el-table>
      <!--工具条-->
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="10"
        :page-sizes="[10, 20, 30, 50]"
        :total="total"
        style="float:right;"
      >
      </el-pagination>
    </el-col>
  </el-row>
</template>
<script>
import API from '@/api/system/api_log'
import _ from 'lodash'
import PagesMixins from '@/mixins/pagesMixins'
let searchForm = {
  operationBusiness: null,
  operationType: null,
  operatorName: '',
  startEndTime: null,
  startOperatorTime: null,
  endOperatorTime: null
}
export default {
  mixins: [PagesMixins],
  data() {
    return {
      searchForm: _.cloneDeep(searchForm),
      listData: [],
      businessOptions: [],
      operationOptions: [],
      operationPlatform: ''
    }
  },
  mounted() {
    this.handleSearch()
    this.loadOperationBusinessOptions()
    this.loadOperationTypeOptions()
  },
  methods: {
    handleSearch() {
      console.log(this.searchForm.startEndTime)
      this.total = 0
      this.page = 1
      this.getListData()
    },
    handleReset() {
      this.searchForm = _.cloneDeep(searchForm)
      this.handleSearch()
    },
    getListData() {
      let params = {
        page: this.page,
        size: this.size,
        ...this.searchForm
      }
      if (params.startEndTime) {
        params.startOperatorTime = params.startEndTime[0]
        params.endOperatorTime = params.startEndTime[1]
      }
      delete params.startEndTime
      API.queryOperationLogList(params).then(result => {
        if (result && result.code === '000000') {
          this.total = result.data.total
          this.listData = result.data.list
        }
      })
    },
    loadOperationBusinessOptions() {
      API.operationBusinessCombobox().then(result => {
        if (result && result.code === '000000') {
          this.businessOptions = result.data.list
        }
      })
    },
    loadOperationTypeOptions() {
      API.operationTypeCombobox().then(result => {
        if (result && result.code === '000000') {
          this.operationOptions = result.data.list
        }
      })
    }
  }
}
</script>
